/* src/components/Header.css */
.header {
     background-color: #282c34;
    padding: 20px;
    color: white;
    text-align: center;
  }
  
  .header nav a {
    margin: 0 15px;
    color: white;
    text-decoration: none;
  }
  
  .header nav a:hover {
    text-decoration: underline;
  }
  